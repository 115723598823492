<!-- eslint-disable max-len -->
<template>
  <div class='menu-container'>
    <div class='menu-left' @click='sendToHome'>
      <img src='~@/assets/img/sharf-logo-filled.svg'/>
    </div>
    <div class='menu-center'>
      <div class='menu-item' @click='sendToHome'>
        <div class='menu-button' :class="[currentNavSection === 'servicio' ? 'active' : '']">
          <Icon name='TruckRight' :variant="currentNavSection === 'servicio' ? 'bold' : 'light'" />
          <div class='text-m-regular'>Servicios</div>
        </div>
      </div>
      <div class='menu-item' @click="sendToContacts" v-if="isAuthenticated">
        <div class='menu-button' :class="[currentNavSection === 'contactos' ? 'active' : '']">
          <Icon name='Contacts_3' :variant="currentNavSection === 'contactos' ? 'bold' : 'light'" />
          <div class='text-m-regular'>Contactos</div>
        </div>
      </div>
      <div class='menu-item' @click="sendToDirections" v-if="isAuthenticated">
        <div class='menu-button' :class="[currentNavSection === 'direcciones' ? 'active' : '']">
          <Icon name='Pin13' :variant="currentNavSection === 'direcciones' ? 'bold' : 'light'" />
          <div class='text-m-regular'>Mis direcciones</div>
        </div>
      </div>
      <div class='menu-item' @click="sendToCards" v-if="isAuthenticated">
        <div class='menu-button' :class="[currentNavSection === 'mis_tarjetas' ? 'active' : '']">
          <Icon name='BankCardDouble' :variant="currentNavSection === 'mis_tarjetas' ? 'bold' : 'light'" />
          <div class='text-m-regular'>Tarjetas</div>
        </div>
      </div>
      <div class='menu-item' @click="sendToHistory" v-if="isAuthenticated">
        <div class='menu-button' :class="[currentNavSection === 'historial' ? 'active' : '']">
          <Icon name='BoardTasks' :variant="currentNavSection === 'historial' ? 'bold' : 'light'" />
          <div class='text-m-regular'>Mis envíos</div>
        </div>
      </div>
    </div>
    <div class='menu-actions'>
      <div class='tracking-button-container'>
        <ButtonDefinitive
          variant='ghost'
          size='m'
          label='Tracking'
          @click="sendToTracking"
        />
      </div>
      <div class='wsp-button' @click="toWspHelp" v-if="isAuthenticated">
        <svg width="65" height="56" viewBox="0 0 65 56" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#a)"><rect x="12.354" y="4" width="40" height="40" rx="12" fill="#25D366" shape-rendering="crispEdges"/><path fill-rule="evenodd" clip-rule="evenodd" d="M39.406 16.906A9.84 9.84 0 0 0 32.396 14c-5.463 0-9.91 4.445-9.911 9.91a9.9 9.9 0 0 0 1.323 4.954L22.402 34l5.253-1.378a9.9 9.9 0 0 0 4.736 1.206h.004c5.462 0 9.909-4.446 9.91-9.91a9.85 9.85 0 0 0-2.9-7.011m-7.01 15.248h-.004a8.2 8.2 0 0 1-4.193-1.148l-.3-.178-3.118.817.832-3.04-.196-.311a8.2 8.2 0 0 1-1.26-4.384c.003-4.541 3.698-8.236 8.242-8.236 2.2 0 4.268.858 5.823 2.416a8.2 8.2 0 0 1 2.41 5.827c-.001 4.542-3.697 8.237-8.237 8.237m4.518-6.169c-.248-.124-1.465-.722-1.693-.805s-.392-.124-.556.124-.64.806-.785.97c-.144.166-.288.187-.536.063-.248-.125-1.046-.386-1.992-1.23-.736-.656-1.233-1.467-1.377-1.715-.145-.248-.015-.382.108-.505.111-.111.248-.29.372-.434s.165-.248.247-.413c.083-.165.042-.31-.02-.434s-.557-1.343-.764-1.838c-.2-.483-.405-.417-.557-.425a9 9 0 0 0-.474-.009.9.9 0 0 0-.66.31c-.228.248-.868.847-.868 2.066s.887 2.396 1.012 2.561c.124.166 1.746 2.667 4.23 3.74a14 14 0 0 0 1.412.521c.593.19 1.132.163 1.56.099.475-.072 1.464-.6 1.67-1.178.207-.578.207-1.074.145-1.177s-.227-.166-.475-.29" fill="#fff"/></g><defs><filter id="a" x="4.354" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="4"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_40_1066"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_40_1066" result="shape"/></filter></defs></svg>
      </div>
    </div>

    <div class='menu-right' v-if="isAuthenticated">
      <div class='menu-item dropdown-container_2'>
        <div class='menu-button'>
          <Icon name='UserCircle' />
          <div class='text-m-regular'>Mi perfil</div>
        </div>
        <div class='dropdown-content_2'>
          <ButtonDefinitive
            variant='ghost'
            size='s'
            label='Mi perfil'
            @click='sendToProfile'
          />
          <ButtonDefinitive
            variant='secondary'
            size='s'
            label='Cerrar sesión'
            @click="logout"
          />
        </div>
      </div>
    </div>
    <div class='menu-right' v-else>
      <div class='menu-item'>
        <router-link to="/signIn">
          <ButtonDefinitive
            variant='ghost'
            size='m'
            label='Inicia sesión'
          />
        </router-link>
      </div>
      <div class='menu-item'>
        <router-link to="/signUp/">
          <ButtonDefinitive
            variant='secondary'
            size='m'
            label='Registrarse'
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: "ScharffNav",
  components: { ButtonDefinitive, Icon },
  data() {
    return {
      authenticated: false,
    };
  },
  props: {
    isAuthenticated: Boolean,
  },
  methods: {
    logout() {
      this.trackEvent('click_menu_logout', 'menu')
      try {
        this.$store.dispatch("LOGOUT");
        window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
      } catch (error) {
        console.error(error);
      }
    },
    sendToTracking() {
      this.trackEvent('click_menu_tracking', 'menu')
      window.open(process.env.VUE_APP_TRACKING_URL, "_blank");
    },
    sendToHome() {
      sessionStorage.setItem("beenhere", "0");
      this.$store.dispatch("STYLE_HOME_DIV");
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "servicio");
      this.trackEvent('click_menu_home', 'login')
      window.location.href = process.env.VUE_APP_WEBURL;
    },
    sendToFavorites() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "favoritos");
      this.$router.push("/favorites").catch(() => {});
      this.trackEvent('click_menu_favoritos', 'menu')
    },
    sendToContacts() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "contactos");
      this.$router.push("/contactos").catch(() => {});
      this.trackEvent('click_menu_contactos', 'menu')
    },
    sendToDirections() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "direcciones");
      this.$router.push("/mis-direcciones").catch(() => {});
      this.trackEvent('click_menu_direcciones', 'menu')
    },
    sendToCards() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_CARDS_HISTORY
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "mis_tarjetas");
      this.$router.push("/tarjetas").catch(() => {});
      this.trackEvent('click_menu_mis_tarjetas', 'menu')
    },
    sendToHistory() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_CARDS_HISTORY
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "historial");
      this.$router.push("/mis-envios").catch(() => {});
      this.trackEvent('click_menu_mis_envios', 'menu')
    },
    sendToProfile() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "profile");
      this.$router.push("/mi-perfil").catch(() => {});

      this.trackEvent('click_menu_perfil', 'menu')
    },
    toWspHelp() {
      this.trackEvent('click_menu_whatsapp', 'menu')
      window.open(
        "https://api.whatsapp.com/send/?phone=51998140976&text=Hola,+necesito+ayuda&type=phone_number&app_absent=0",
        "_blank"
      );
    },
  },
  computed: {
    currentNavSection() {
      return this.$store.getters.HELPER_CURRENT_NAV_SECTION;
    },
  },
};
</script>

<style lang='scss'>
</style>
