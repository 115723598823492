<template>
  <div :id="id" class="c-box-app-map"></div>
</template>

<script>
export default {
  name: "MapSaio",
  props: {
    id: String,
  },
};
</script>

<style></style>
