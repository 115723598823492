<template>
  <div>
    <!--@start vue Notification -->
    <div v-if="attributes.visible" class="c-notification" :class="classCss">
      <h5 v-html="attributes.title"></h5>
      <div class="text" v-html="attributes.text"></div>
      <div v-if="attributes.link" class="mt-1">
        <a
          class="c-content--button-notification"
          target="popup"
          v-on:click="openPopUp()"
          >{{ attributes.linkText }}</a
        >
      </div>
    </div>
    <!--@end vue Notification -->
  </div>
</template>

<script>
export default {
  props: ["attributes"],
  data() {
    return {
      title: null,
      text: null,
      class: "",
      visible: false,
      link: null,
      linkText: null,
    };
  },
  computed: {
    /**
     * Retorna class css para el componente
     * @returns {string}
     */
    classCss() {
      return this.attributes.class;
    },
  },
  methods: {
    init() {},
    openPopUp() {
      window.open(this.attributes.link, "popup", "width=600,height=600");
      return false;
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
