<template>
  <div id="app">
    <file-pond
      @addfile="handleFilePondAddfile"
      @removefile="handleFilePondRemovefile"
      name="test"
      ref="pond"
      class-name="my-pond"
      :allow-multiple="allowMultiple"
      :accepted-file-types="filetypes"
      @files="files"
    />
  </div>
</template>

<script>
// Import FilePond
import vueFilePond, { setOptions } from "vue-filepond";

// Import plugins
// eslint-disable-next-line max-len, import/extensions
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
// eslint-disable-next-line import/extensions
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
// eslint-disable-next-line camelcase, import/extensions
import labels_es_ES from "../../assets/js/filePond_es.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

setOptions(labels_es_ES);
// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "UploadZone",
  data() {
    return {
      files: [],
    };
  },
  props: {
    filetypes: {
      type: String,
      default: "image/png, image/jpeg, image/jpg",
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleFilePondAddfile(error, file) {
      if (error) {
        const filesTypesArray = this.filetypes.split(",");
        if (!filesTypesArray.includes(file.filetype)) {
          this.$refs.pond.removeFile(file);
          this.showError("El tipo de archivo no es válido");
        }

        return;
      }
      this.updateFiles();
    },
    handleFilePondRemovefile() {
      this.updateFiles();
    },
    updateFiles() {
      this.files = [];
      const files = this.$refs.pond.getFiles();
      if (files.length > 0) {
        files.forEach((x) => this.files.push(x.file));
      }
      this.$emit("getFiles", this.files);
    },
  },
  components: {
    FilePond,
  },
};
</script>
