<template>
  <div class="c-nav c-nav--floating-footer-sticky" v-show="order.state == 'PDP'">
    <ButtonDefinitive
      label="Ver recibo"
      @click="sendToPayU"
    />
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step1-Footer",
  components: { ButtonDefinitive },
  async mounted() {
    this.initializeData();
  },
  data() {
    return {
      order: {
        address: {},
        origin: { address: "", contact: {} },
        destination: { addresses: [], contact: { phone: "", name: "" } },
        typeVoucher: {},
        card: {},
        tip: 0,
        coupon: {},
        categoriesIds: [],
        id: 0,
        serviceType: "",
        cardNumber: "",
        categories: [],
        fare: {},
        currency: "S/",
        dateCreate: null,
        dateMiami: null,
        dateTransit: null,
        dateLima: null,
        dateDelivery: null,
      },
      shouldShowTrackingModal: false,
      textTrackingButton: "Registra tu nro. de tracking",
    };
    
  },
  methods: {
  initializeData() {
      const tempOrder = this.$store.getters.HISTORY_ORDER_DETAIL;
      this.order.id = this.$store.getters.HISTORY_ORDER_DETAIL_ID;
      this.order.serviceType = tempOrder.serviceType;
      this.order.state = tempOrder.state;
      this.order.stateDescription = tempOrder.stateClient;
      this.order.date = tempOrder.date;
      this.order.packageValue = tempOrder.packageValue;
      this.order.cardNumber = tempOrder.cardNumber;
      this.order.cardBrand = tempOrder.cardBrand;
      this.order.coupon = tempOrder.coupon;
      this.order.categories = tempOrder.categories;
      this.order.totalOrderFare = tempOrder.totalVoucherFare;
      this.order.pagoEfectivoCip = tempOrder.pagoEfectivoCip;
      this.order.pagoEfectvioExpirationDate = tempOrder.pagoEfectivoExpDate;
      this.order.pagoEfectivoPdfUrl = tempOrder.pagoEfectivoPdfUrl;
      this.order.pagoEfectivoWebUrl = tempOrder.pagoEfectivoWebUrl;
      this.order.code = tempOrder.code;
      if (tempOrder.serviceType === "Compra y Trae") {
        this.order.haveTrackingNumber = tempOrder.haveTrackingNumber;
        this.order.compraTraeTrackingNumbers =
          tempOrder.compraTraeTrackingNumbers;
        if (this.order.haveTrackingNumber)
          this.textTrackingButton = "Ver Tracking number";
        this.order.origin.address = tempOrder.address.Direccion;
        this.order.origin.contact.phone = tempOrder.address.Telefono;
        this.order.origin.contact.name = tempOrder.address.Nombre;
        this.order.weight = tempOrder.packageWeight;
        this.order.fare.total = tempOrder.fare.total;
        this.order.currency = "$";
        const tempDestinationAddress = {
          address: tempOrder.address.Direccion,
          contactPhoneNumber: tempOrder.address.Celular,
          contactName: tempOrder.address.Contacto,
        };
        this.order.destination.addresses.push(tempDestinationAddress);
        this.order.dateCreate =
          tempOrder.dateCreated !== null
            ? this.formatDate(tempOrder.dateCreated)
            : null;
        this.order.dateMiami =
          tempOrder.dateMiami !== null
            ? this.formatDate(tempOrder.dateMiami)
            : null;
        this.order.dateTransit =
          tempOrder.dateTransit !== null
            ? this.formatDate(tempOrder.dateTransit)
            : null;
        this.order.dateLima =
          tempOrder.dateLima !== null
            ? this.formatDate(tempOrder.dateLima)
            : null;
        this.order.dateDelivery =
          tempOrder.dateDelivered !== null
            ? this.formatDate(tempOrder.dateDelivered)
            : null;
      } else {
        this.order.fare.total = tempOrder.totalFare;
        this.order.currency = "S/";
        this.order.origin.address = tempOrder.points[0].address;
        this.order.origin.contact.phone =
          tempOrder.points[0].contactPhoneNumber;
        this.order.origin.contact.name = tempOrder.points[0].contactName;
        this.order.destination.addresses = tempOrder.points.filter(
          (value, index) => index !== 0
        );
      }
      this.$forceUpdate();
    },
    next() {
      this.$store.dispatch("COMPRATRAE_PROGRESS_NAV_STEP", 2);
    },
    sendToPayU() {
        this.trackEvent('click_ver_recibo', 'mispedidos');
        window.open(this.order.pagoEfectivoWebUrl);
      }
  },
};
</script>

<style></style>
