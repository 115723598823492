<template>
  <div class="c-content--top-modal" :id="id">
    <div>
      <!-- <article class="d-flex align-items-center pt-2">
        <div class="c-icon-person pr-1">
          <img src="~@/assets/img/icon/icon-fijar.svg" alt="" loading="lazy" />
        </div>
        <div class="content-article-directions">
          <div class="c-section--article-paragraph-n">
            Fijar lugar en el mapa manualmente
          </div>
        </div>
      </article> -->
      <article class="d-flex align-items-center pt-2">
        <div class="c-icon-person pr-1">
          <img
            src="~@/assets/img/icon/icon-starsend.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="content-article-directions">
          <div class="c-section--article-paragraph-n">Lugares favoritos</div>
        </div>
      </article>
      <div class="c-grid--services__container-m pt-1">
        <div class="c-section--content-article">
          <article
            class="c-grid--services__item c-section--article"
            v-for="favoriteAddress in favoriteAddresses"
            :key="favoriteAddress.favoriteLocationId"
            v-on:click="sendAddress(favoriteAddress)"
          >
            <div class="c-flex">
              <div class="c-icon-person">
                <img src="~@/assets/img/icon/icon-ubicacion.svg" alt="" />
              </div>
              <div class="content-article-directions">
                <div class="c-section--article-paragraph-n">
                  {{ favoriteAddress.address }}
                </div>
                <div class="c-section--article-paragraph">
                  {{ favoriteAddress.name }}
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FavoriteAddress",
  props: {
    id: String,
    modalId: String,
  },
  data() {
    return {};
  },
  methods: {
    async getFavoriteAddresses() {
      this.showLoading();
      this.$store
        .dispatch("GET_FAVORITE_LOCATIONS")
        .then((success) => {
          if (success) {
            this.$swal.close();
          } else {
            this.showError("Por favor, vuelva a iniciar sesion.");
          }
        })
        .catch(() => {
          this.showError("Por favor, vuelva a iniciar sesion.");
        });
    },
    sendAddress(favoriteAddress) {
      this.$emit("getSelectedAddress", favoriteAddress);
    },
    getContact(selectedContact) {
      this.$emit("getSelectedContact", selectedContact);
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getFavoriteAddresses();
    });
  },
  computed: {
    favoriteAddresses() {
      return this.$store.getters.GENERAL_FAVORITE_ADDRESSES;
    },
  },
};
</script>

<style></style>
