<!-- eslint-disable max-len -->
<template>
  <svg
    id="c-scharff-logo"
    data-name="c-scharff-logo"
    xmlns="http://www.w3.org/2000/svg"
    width="110" height="32" viewBox="0 0 110 32"
  >
    <g clip-path="url(#a)" fill="#000">
      <path d="M91.763 9.09h-2.328c-3.52.01-6.085 1.195-8.502 4.723h-.076l1.009-4.723h-7.438l-4.604 21.64h7.437l2.705-12.708c.55-2.558 1.495-3.424 3.895-3.424h6.735zm-51.688-.787c-2.397 0-4.673 1.162-6.57 4.525h-.11c.297-1.072.48-1.79.615-2.391L36.231 0h-7.437l-6.532 30.731H29.7l2.95-13.865c.54-2.251 1.776-3.25 3.494-3.25 1.81 0 2.834 1.14 2.204 4.17l-2.76 12.945h7.438L46.1 16.37c1.136-5.35-1.697-8.066-6.025-8.066m65.184-2.834h3.481L109.9 0h-6.936c-4.88 0-7.28 2.36-8.224 6.728l-5.1 24.003h7.436l3.43-16.132h5.799l1.171-5.51h-5.8l.123-.59c.478-2.204 1.612-3.03 3.461-3.03M14.47 17.157h-2.531c-1.133 0-1.681-.402-1.681-1.132 0-.984.983-1.425 2.229-1.425h10.235l1.172-5.51H13.838c-7.594 0-11.017 2.99-11.017 7.28 0 3.62 2.479 5.666 6.335 5.666h1.383c2.007 0 2.637.59 2.637 1.495 0 1.141-.984 1.889-3.109 1.889h-8.84l-1.13 5.31H9.91c7.359 0 11.333-2.872 11.333-7.711.004-3.523-2.143-5.837-6.774-5.862m55.237-2.563a4 4 0 0 0-.033-.234c-.566-3.5-3.32-5.27-7.017-5.27H50.1l-1.17 5.51h9.485c-4.5 2.529-8.922 6.55-11.122 10.4-3.008 5.26-.72 8.188 5.11 6.543 2.46-.692 5.142-2.09 7.658-3.858l-.648 3.047h7.438l2.64-12.419c.297-1.4.356-2.637.215-3.72m-9.548 12.635c-1.76.91-3.406 1.283-4.382.879-1.675-.696-.748-3.42 2.073-6.087a16.7 16.7 0 0 1 4.016-2.819z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.098 0h109.805v32H.098z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ScharffLogo",
};
</script>
