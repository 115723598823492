<template>
 
  <div class='sidebar-v2'>

    <div class='sidebar-v2__profile'>
      <div class='sidebar-v2__profile-icon'>
        <Icon name='UserCircle' variant='bold' />
      </div>
      <div v-if='isAuthenticated' class='text-xl-bold'>{{ user.fullName }}</div>
      <div v-else style='width: 170px;'>
        <router-link to="/signIn">
          <ButtonDefinitive
            variant='secondary'
            size='m'
            label='Iniciar sesión'
          />
        </router-link>
      </div>
    </div>

    <div class='sidebar-v2__menu'>

      <div class='sidebar-v2__menu-item'>
        <div class='sidebar-v2__menu-item-button-container'>
          <ButtonDefinitive
            variant='ghost'
            size='m'
            label='Tracking'
            @click="sendToTracking"
          />
        </div>
      </div>

      <div v-if='isAuthenticated' class='sidebar-v2__menu-tabs'>

        <div class='sidebar-v2__menu-tabs__tab'>
          <div class='sidebar-v2__menu-tabs__tab-content' :class="[currentNavSection === 'profile' ? 'active' : '']" @click="sendToProfile">
            <Icon name='UserCircle' />
            <div class='text-m-regular'>Mi perfil</div>
          </div>
        </div>

        <div class='sidebar-v2__menu-tabs__tab'>
          <div class='sidebar-v2__menu-tabs__tab-content' :class="[currentNavSection === 'contactos' ? 'active' : '']" @click="sendToContacts">
            <Icon name='Contacts_3' />
            <div class='text-m-regular'>Contactos</div>
          </div>
        </div>

        <div class='sidebar-v2__menu-tabs__tab'>
          <div class='sidebar-v2__menu-tabs__tab-content' :class="[currentNavSection === 'direcciones' ? 'active' : '']" @click="sendToDirections">
            <Icon name='Pin13' />
            <div class='text-m-regular'>Mis direcciones</div>
          </div>
        </div>

        <div class='sidebar-v2__menu-tabs__tab'>
          <div class='sidebar-v2__menu-tabs__tab-content' :class="[currentNavSection === 'mis_tarjetas' ? 'active' : '']" @click="sendToCards">
            <Icon name='BankCardDouble' />
            <div class='text-m-regular'>Tarjetas</div>
          </div>
        </div>

        <div class='sidebar-v2__menu-tabs__tab'>
          <div class='sidebar-v2__menu-tabs__tab-content' :class="[currentNavSection === 'historial' ? 'active' : '']" @click="sendToHistory">
            <Icon name='BoardTasks' />
            <div class='text-m-regular'>Mis envíos</div>
          </div>
        </div>

        <div class='sidebar-v2__menu-tabs__tab'>
          <div class='sidebar-v2__menu-tabs__tab-content logout' @click="logout">
            <Icon name='Logout' />
            <div class='text-m-regular'>Cerrar sesión</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "SidebarContent",
  components: { Icon, ButtonDefinitive },
  data() {
    return {
      authenticated: false,
    };
  },
  mounted() {},
  props: {
    isAuthenticated: Boolean,
  },

  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
    },
    sendToHome() {
      sessionStorage.setItem("beenhere", "0");
      this.$store.dispatch("STYLE_HOME_DIV");
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "servicio");
      window.location.href = process.env.VUE_APP_WEBURL;
    },
    sendToTracking() {
      window.open(process.env.VUE_APP_TRACKING_URL, "_blank");
    },
    sendToFavorites() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "favoritos");
      this.$router.push("/favorites").catch(() => {});
    },
    sendToContacts() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "contactos");
      this.$router.push("/contactos").catch(() => {});
    },
    sendToDirections() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "direcciones");
      this.$router.push("/mis-direcciones").catch(() => {});
    },
    sendToCards() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_CARDS_HISTORY
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "mis_tarjetas");
      this.$router.push("/tarjetas").catch(() => {});
    },
    sendToHistory() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_CARDS_HISTORY
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "historial");
      this.$router.push("/mis-envios").catch(() => {});
    },
    sendToProfile() {
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "profile");
      this.$router.push("/mi-perfil").catch(() => {});
    },
    closeModal() {
      this.closeSidebar();
    },
  },
  computed: {
    user() {
      return this.$store.getters.USER;
    },
    currentNavSection() {
      return this.$store.getters.HELPER_CURRENT_NAV_SECTION;
    },
  },
};
</script>

<style lang='scss'>
.box-tracking {
  display: flex;
  padding-top: 32px;
  padding-left: 19px;
}
.tracking-button-mobile {
  display: flex;
  height: 40px;
  width: auto;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--primary-500, #FF565D);
  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}
.tracking-button-mobile:hover {
  scale: 1.02;
  background: var(--primary-700, #993438);
}
.tracking-button-mobile:active {
  background: var(--primary-700, #993438);
}
.tracking-button-mobile:focus {
  background: var(--primary-500, #FF565D) !important; 
  box-shadow: 0px 0px 0px 2px #1A1A1A, 0px 0px 0px 4px #FFF;
}

.sidebar-v2 {
  &__profile {
    padding: 48px 20px;

    &-icon {
      svg {
        width: 56px;
        height: 56px;
        path { fill: #FF6568 }
      }
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-item {
      padding: 0 20px;
      &-button-container { width: 100px}
    }

    &-tabs {
      padding: 0 20px 0 8px;
      display: flex;
      flex-direction: column;

      &__tab {
        height: 48px;
        display: flex; align-items: center;

        &-content {
          padding: 8px 12px;
          border-radius: 12px;
          display: flex; gap: 8px; align-items: center;
          cursor: pointer;
          transition: .3s;

          &.logout {
            div { color: #B20000 !important }
            svg { path { stroke: #B20000 } }
          }

          &:hover { background: rgba(0, 0, 0, 0.08) }
          &:active {
            background: #FFEFEF;
            div { color: #68002B !important }
            svg { path { stroke: #68002B } }
          }

          &.active {
            background-color: #fff1f2;
            border-radius: 1rem;
            div { color: #68002B !important }
            svg { path { fill: #68002B } }
          }
        }
      }
    }
  }
}
</style>
