<template>
  <div
    v-if="options.show && route == 'Home'"
    style="
      position: absolute;
      top: 0;
      bottom: 0;
      right: 50px;
      height: 94%;
      width: calc(100vw - 590px);
      margin: auto;
      border-radius: 0.5em;
      background-color: #FFEFEF;
    "
  >
    <div
      class="flex flex-column"
      style="padding: 2rem; color: #68002B; height: 120px"
    >
      <span>Envío</span>
      <div class='text-4xl-bold' style="margin: 0px; padding: 0px">{{ options.title }}</div>
    </div>
    <div
      class="border-radius-above"
      style="
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: min-content;
        grid-gap: 1em;
        background-color: white;
        height: calc(100% - 270px);
        padding: 2rem;
        overflow-y: auto;
      "
    >
      <div
        v-for="(card, index) in options.cards"
        :key="index"
        class="flex flex-column"
        style="border: solid 1px #cbcbcb; border-radius: 1em; padding: 15px"
      >
        <div class='text-xl-bold' style='padding-bottom: 10px'>{{ card.title }}</div>
        <div v-if="card.columns" class="flex" style="justify-content: space-between">
          <div class="flex flex-column">
            <div class='text-m-regular'>{{ card.columns[0].text }}</div>
            <div class='text-m-regular'>{{ card.columns[0].description }}</div>
          </div>
          <div class="flex flex-column">
            <div class='text-m-regular'>{{ card.columns[1].text }}</div>
            <div class='text-m-regular'>{{ card.columns[1].description }}</div>
          </div>
        </div>
        <span v-else-if="card.isHtml" v-html="card.text"></span>
        <div v-else class='text-m-regular' style="color: #737373">{{ card.text }}</div>
        <span v-if="card.extra" style=" font-size: 0.8rem; font-weight: 400; color: #4c4c4c; padding-top: 5px">{{ card.extra }}</span>
        <button v-if="card.buttonAction" class="c-button c-button--eight c-button--width-fuid c-button--mobile-small waves-effect" style="max-width: 250px; height: 45px; margin-top: 10px" @click="callActionCard(card)">
          {{ card.buttonText }}
        </button>
      </div>
    </div>
    <div
      class="flex"
      style="
        height: 150px;
        background-color: white;
        text-align: center;
        align-items: center;
        justify-content: center;
      "
    >
      <router-link :to="options.route">
        <ButtonDefinitive
          label='Empieza a enviar'
        />
        <!-- <button
          class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
          style="max-width: 250px; height: 45px"
        >
          Empieza a enviar
        </button> -->
      </router-link>
    </div>
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  components: {
    ButtonDefinitive,
  },
  name: "ServiceInfo",
  computed: {
    options() {
      
      return this.$store.getters.SERVICE_INFO;
    },
    route() {
      return this.$route.name;
    },
  },
  methods: {
    callActionCard(card) {
      try {
        // document.getElementById(card.buttonAction).click();
        const modal = document.querySelector(`#${card.buttonAction}`);
        const instance = M.Modal.getInstance(modal);
        if (instance) instance.open();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
span ul {
  list-style-type: auto !important;
  padding-left: 20px !important;
}
span ul li {
  list-style-type: disc !important;
}
</style>
