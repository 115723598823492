<!-- eslint-disable max-len -->
<template>
  <div class='history_item_container'>
    <article class='c-grid--services__item'>
      <div class='c-section--content-date' style='padding-left: 0; padding-right: 0;'>
        <div class='date-info disabled-shadow'>
          <div class='date-info-content-box' style='padding-left: 0; padding-right: 0;'>
            <div class='font-weight-bold'>
              <div class='text-s-bold'>
                {{ order.serviceType }}
              </div>
              <div class='text-s-bold'>
                {{ order.code }}
              </div>
            </div>
            <div class='info-light'>
              <div class='text-s-bold' style='color: #FFB126'>
                {{ order.statusClient.toUpperCase() }}
              </div>
              <div class='text-s-regular'>
                {{ order.date }}
              </div>
            </div>
          </div>
          <div v-if="order.serviceType === 'Compra y Trae' && !order.haveTrackingNumber" style='padding: 0.6em 1em; padding-top: 0px'>
            <span style='color: red; font-weight: bold'>Tracking Number Pendiente</span>
          </div>
        </div>
      </div>
    </article>
    <article class='c-grid--services__item'>
      <div class='c-section--point not-before p-0'>
        <div v-if="order.section !== 'CYT'" class='c-section--point-pick'>
          <ul>
            <li class='info-black'>Lugar de recojo</li>
            <li class='text-s-regular'>{{ order.originAddress }}</li>
          </ul>
        </div>

        <div class='c-section--point-delivery c-section--point-pick--end'>
          <div
            :class="[
              index === order.destinationAddress.length - 1
                ? 'c-section--point-delivery'
                : 'c-section--point-delivery-multiple'
            ]"
            v-for='(destination, index) in order.destinationAddress' :key='destination.index'
          >
            <ul>
              <li class='info-black'>Dirección de entrega</li>
              <li class='text-s-regular'>{{ destination }}</li>
            </ul>
          </div>

          <div v-if='order.esPagoContraEntrega' style='display: flex; gap: 10px; align-items: center'>
            <Icon name='Bank-card' />
            <div class='text-s-regular'>Pago de envío al recoger el paquete</div>
          </div>

          <div style='width: 150px'>
            <ButtonDefinitive variant='tertiary' size='s' label='Ver detalle' @click='goToDetail()' />
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'HistoryItem',
  components: {
    ButtonDefinitive,
    Icon
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  methods: {
    goToDetail() {
      this.showLoading('Cargando...')
      this.$store.dispatch('HISTORY_ORDER_DETAIL_ID', this.order.id)

      const method =
        this.order.serviceType === 'Compra y Trae'
          ? 'HISTORY_GET_ORDER_CYT_BY_ID'
          : 'HISTORY_GET_ORDER_BY_ID'

      this.$store
        .dispatch(method, this.order.id)
        .then((response) => {
          this.$store.dispatch('HISTORY_ORDER_DETAIL', response)

          this.$store.dispatch('HISTORY_CURRENT_STEP', 2)
          this.$swal.close()
        })
        .catch(() => {
          Error('Error al obtener el detalle del pedido')
        })

      this.trackEvent('ver_detalle', 'mispedidos')
    },
  },
}
</script>
<style scoped lang='scss'>
.history_item_container {
  padding: 0 20px;
  margin: 10px 10px;
  border-radius: 14px;
  border: 1px solid #dddddd;
}
</style>
