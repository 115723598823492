<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <ButtonDefinitive
      label="Descargar envíos"
      @click="next"
    />
    <!-- <button
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="next"
    >
      Descargar envíos
    </button> -->
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step1-Footer",
  components: { ButtonDefinitive },
  methods: {
    next() {
      this.$store.dispatch("DOWNLOAD_HISTORY_REPORT");
    },
  },
};
</script>

<style></style>
