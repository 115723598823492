<template>
  <article class='c-content--sendPoint c-scrollbar container_history_v2'>
    <div class='c-grid c-section--content-favorite c-m-top-for-nav-md'>
      <div class='c-section--content-article'>
        <article class='c-grid--services__item m-1'>
          <div class='c-section--content-date'>
            <div class='date-info disabled-shadow'>
              <div class='date-info-content-box p-0'>
                <div class='info-black'>
                  Resultado de búsqueda <br />
                  <span class='info-light'>
                    {{ orders.length }} {{ orders.length !== 1 ? 'resultado encontrado' : 'resultados encontrados' }}
                  </span>
                </div>
                <!-- <div class='info-light'>
                  <a href='javascript:void(0)' id='btnOpenModalSearch'>
                    <img src='~@/assets/img/icon/icon-filtro.svg' alt='' width='25' height='auto' />
                  </a>
                </div> -->
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>

    <history-item
      v-for='(order, index) in orders'
      :key='order.index'
      :order='order'
      :index='index'
    />

    <modal-search />
  </article>
</template>
<script>
import ModalSearch from '../Modals/ModalSearch.vue';
import HistoryItem from '../General/HistoryItem.vue';

export default {
  name: 'HistoryCmp',
  components: {
    ModalSearch,
    HistoryItem,
  },
  mounted() {
    this.$nextTick(() => {
      this.initMain()

      this.getOrders()
    })
  },

  methods: {
    getOrders() {
      this.showLoading("Cargando...")
      this.$store
        .dispatch("HISTORY_FETCH_ORDERS")
        .then(() => {
          this.$swal.close()
        })
        .catch((error) => {
          this.showError(error)
        })
    },
  },
  computed: {
    orders() {
      return this.$store.getters.HISTORY_ORDERS
    },
  },
}
</script>
<style>
@media screen and (min-width: 780px) {
  .container_history_v2 {
    padding-top: 60px !important;
  }
}
</style>
