<template>
  <div class="pt-0" style="">
      <div class="" style="padding:0 !important;">
        <div class="" style="">
          <img style="width:100%;" src="https://saio.holascharff.com/v2/img/chaucyt.v4.png">
        </div>
      </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  data() {
    return {
      settingSlider: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 6000,
        cssEase: "linear",
      },
    };
  },
  components: {
    VueSlickCarousel,
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
