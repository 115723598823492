<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <div class="row m-0">
      <div class="col s12">
        <div class="paragraph-home center-align pb-1">
          Estas navegando actualmente como invitado.
        </div>
      </div>
      <div style='display: flex; gap: 14px; width: 100%; padding: 8px 12px 0 12px'>
        <ButtonDefinitive
          label='Regístrate'
          @click="handleRegisterClick"
        />
        <ButtonDefinitive
          variant='ghost'
          label='Inicia sesión'
          @click="handleSignInClick"
        />
      </div>
      </div>
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "GuestFooter",
  components: {
    ButtonDefinitive,
  },
  methods: {
    handleRegisterClick() {
      this.$router.replace({ name: "Step1" });
    },
    handleSignInClick() {
      this.$router.replace({ name: "SignIn" });
    },
  },
};
</script>

<style></style>
