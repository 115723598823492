<template>
  <div class="shipping_cost_container">
    <div class="label_shipping_cost">Costo de envío:</div>
    <RingLoader :size="3" hexColor="A0A0A0" v-if="isLoading" />
    <div v-else class="amount">S/ {{ amounthFormated }}</div>
  </div>
</template>

<script>
import RingLoader from '../Loaders/RingLoader.vue';

export default {
  components: {
    RingLoader,
  },
  props: {
    amount: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    amounthFormated() {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      return formatter.format(this.amount)
    }
  },
}
</script>

<style lang="scss" scoped>
.shipping_cost_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.label_shipping_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #737373;
}

.amount {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #1A1A1A;
}
</style>