<template>
  <div>
    <div class="input-field c-select">
      <input
        :id="id"
        type="text"
        class="autocomplete"
        v-model="districtVaule"
        @input="handleInput"
        v-on:blur="getDistrictsId"
        autocomplete="off"
        @focus="$emit('focus')"
      />
      <label :for="id">{{ text }}</label>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

export default {
  name: "InputDisctrict",
  props: {
    id: String,
    text: String,
    serviceType: String,
  },
  data() {
    return {
      districId: null,
      districtVaule: "",
      lstDistricts: [],
      options: {
        data: { a: null, b: null },
        onAutocomplete: (e) => {
          this.districtVaule = e;
          this.getDistrictsId();
        },
      },
    };
  },
  mounted() {
    this.setOptions();
    this.$eventBus.$on("inputDistrictSetDistict", (district) => {
      this.setDistrict(district);
    });
  },
  methods: {
    setOptions() {
      const elemsAutocomplete = document.querySelectorAll(`#${this.id}`);
      const instances = M.Autocomplete.init(elemsAutocomplete, this.options);
      instances[0].open();
    },
    async getOptions() {
      if (this.districtVaule.length === 3) {
        const list = {};
        const district = this.districtVaule;
        if (this.serviceType === "2" || this.serviceType === "3") {
          // this.showLoading("Cargando...");
          this.$store
            .dispatch("GET_DISTRICTS", district)
            .then((response) => {
              this.lstDistricts = response;
              // eslint-disable-next-line no-restricted-syntax
              for (const value of this.lstDistricts) {
                list[value.text] = null;
              }
            })
            .then(() => {
              this.options.data = list;
              this.$swal.close();
              this.setOptions();
            });
        }
      }
    },
    async getOptionApi(id) {
      const list = {};
      this.$store
        .dispatch("GET_DISTRICTS", id)
        .then((response) => {
          this.lstDistricts = response;
          // eslint-disable-next-line no-restricted-syntax
          for (const value of this.lstDistricts) {
            list[value.text] = null;
          }
        })
        .then(() => {
          this.options.data = list;
          this.setOptions();
        });
    },
    getDistrictsId() {
      const district = this.lstDistricts.find((el) => this.isDistrict(el));

      if (district) {
        this.$emit("getDistrictId", { id: district.id, text: district.text });
        this.$emit("getProvinceId", district.provinceId);
        this.$emit("getDepartmentId", district.departmentId);
      }
      // TODO : Utilizar solo cuando funcione bien el mostrar desplegables
      // else {
      //   alert("Tiene que elegir una dirección válida");
      //   this.districtVaule = null;
      // }
    },
    async setDistrict(district) {
      await this.getOptionApi(district.text);
      this.districtVaule = district.text;
      this.getDistrictsId();
    },
    isDistrict(element) {
      return element.text === this.districtVaule;
    },
    handleInput(e) {
      const { value } = e.target;
      this.districtVaule = value;
      this.getOptions();
      this.$emit("input", value);
    },
  },
};
</script>

<style>
.autocomplete-content {
  top: 44.719px !important;
  max-height: 140px;
}
</style>
