import { render, staticRenderFns } from "./ShippingCost.vue?vue&type=template&id=50f54212&scoped=true&"
import script from "./ShippingCost.vue?vue&type=script&lang=js&"
export * from "./ShippingCost.vue?vue&type=script&lang=js&"
import style0 from "./ShippingCost.vue?vue&type=style&index=0&id=50f54212&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f54212",
  null
  
)

export default component.exports