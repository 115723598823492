<template>
  <div>
    <div class="c-data-pick">
      <div class="c-data-pick__container">
        <date-pick
          v-model="date"
          @input="getDate"
          :isDateDisabled="setDatePicker"
          :hasInputElement="false"
        ></date-pick>
      </div>
    </div>
  </div>
</template>

<script>
import DatePick from "vue-date-pick";

export default {
  components: { DatePick },
  data() {
    return {
      date: "",
      holidays: [],
    };
  },
  mounted() {
    this.$eventBus.$on("setDateModal", (date) => {
      this.setDateModal(date);
    });
    const startDate = this.getValidStartDate();
    startDate.setDate(startDate.getDate() + 1);
    if (this.isSunday(startDate)) startDate.setDate(startDate.getDate() + 1);

    this.date = this.formatDate(startDate);
    console.log("🚀 ~ file: AppCalendar.vue:37 ~ mounted ~ date", this.date);
    this.$emit("getDate", this.date);
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join("-");
    },

    isSunday(date) {
      // eslint-disable-next-line no-underscore-dangle
      const _date = date.getDay();
      return _date === 0;
    },

    isDateHoliday(date) {
      return this.holidays.some(
        (x) =>
          x.Dia === date.getDate() &&
          x.Mes === date.getMonth() + 1 &&
          x.Anio === date.getFullYear()
      );
    },
    getValidStartDate() {
      const today = new Date();
      const isAfterMidday = today.getHours() >= 12 && today.getMinutes() >= 0;

      if (isAfterMidday) {
        const result = new Date(today);
        return result;
      }
      const result = new Date();
      result.setDate(today.getDate() - 1);

      return result;
    },

    setDatePicker(date) {
      const isHolidayOnDecember = [8, 9].includes(date.getDate()) && date.getMonth() + 1 === 12
      const startDate = this.getValidStartDate()
      return startDate >= date || this.isSunday(date) || isHolidayOnDecember
    },
    getDate() {
      this.$emit("getDate", this.date);
      this.trackEvent('programados_paso_1_click_calendario', 'programado')
    },
    setDateModal(date) {
      this.date = date;
    },
  },
  computed: {},
};
</script>
