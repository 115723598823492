<template>
  <div>
    <!--@start vue AppProgressNav -->
    <div class="c-nav c-nav--floating-top" :class="_setVisiblePC">
      <div v-if="showButtonBack" class='floating-arrow-left' @click="onClickBack">
        <Icon name='arrow-left' />
      </div>
      <div class="text-s-bold" style='color: #121212; text-align: center;'>{{ title }}</div>
      <div class="text-xs-regular" style='color: #737373; text-align: center;'>{{ stepsText }}</div>
      <div class="progress c-progress">
        <div class="determinate" :style="_setPercent"></div>
      </div>
    </div>
    <!--@start vue AppProgressNav -->
  </div>
</template>

<script>
import M from "materialize-css";
import Icon from '@/components/Icon.vue'

export default {
  props: ["title", "stepsText", "progress", "showButtonBack", "visiblePc", "helpModalId"],
  components: { Icon },
  data() {
    return {};
  },

  computed: {
    // eslint-disable-next-line no-underscore-dangle
    _setPercent() {
      const css = `width:${this.progress}%`;
      return css;
    },

    // eslint-disable-next-line no-underscore-dangle
    _setVisiblePC() {
      const classCss = this.visiblePc === "true" ? "c-nav--floating-top--visible-pc" : "";
      return classCss;
    },

    // eslint-disable-next-line no-underscore-dangle
    _setVisibleBotonHelp() {
      const visible = !!this.helpModalId;
      return visible;
    },
  },
  methods: {
    onClickBack() {
      this.setStepProgressNav();
    },
    onClickHelp() {
      const elem = document.querySelector(`#${this.helpModalId}`);
      const modalWelcome = M.Modal.init(elem, {});

      if (modalWelcome) {
        modalWelcome.open();
      }
    },
    setStepProgressNav() {
      switch (this.$store.getters.CURRENT_SERVICE) {
        case 2:
          this.programmedProgressNav();
          break;
        case 4:
          this.scharffPointProgressNav();
          break;
        default:
          this.genericProgressNav();
          break;
      }
    },
    programmedProgressNav() {
      const progressNav = this.$store.getters.PROGRAMMED_PROGRESS_NAV;
      const { step } = progressNav;
      
      this.trackEvent(`click_programado_paso_${step}_volver`, 'programado')

      if (step === 1) {
        window.location.href = process.env.VUE_APP_WEBURL;
        this.$store.dispatch("STYLE_MAP_SAIO", false);
      } else {
        if (step - 1 === 1) {
          this.$store.dispatch("STYLE_PROGRAMMED_STEP_DIV_ONE");
          this.$store.dispatch("STYLE_HOME_DIV");
          // this.$store.dispatch("STYLE_SHOW_PM_QUOTER", true);
          this.$store.dispatch("STYLE_MAP_SAIO", false);
        }
        this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", step - 1);
      }
    },
    genericProgressNav() {
      const step = this.$store.getters.EXPRESS_STEP;
      if (step === 1) {
        sessionStorage.setItem("beenhere", "0");
        window.location.href = process.env.VUE_APP_WEBURL;
      } else {
        this.$store.dispatch("EXPRESS_PROGRESS_NAV_STEP", step - 1);
      }
    },
    scharffPointProgressNav() {
      const step = this.$store.getters.SCHARFFPOINT_PROGRESS_NAV_STEP;

      this.trackEvent(`click_puntos_paso_${step}_volver`, 'programado')

      if (step === 1) {
        window.location.href = process.env.VUE_APP_WEBURL;
      } else {
        this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", step - 1);
      }
    },
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>
.c-nav--floating-top {
  padding: 14px 0 0 0 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05) !important;
}
.floating-arrow-left {
  position: absolute;
  left: 15px;
  bottom: 25px;
  cursor: pointer;
}
</style>