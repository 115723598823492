<!-- eslint-disable max-len -->
<template>
  <div class="add_card_container">
    <div class="add_card_container--inputs">
      <InputText
        label='Número de tarjeta'
        placeholder='Número de tarjeta'
        v-model='cardNumber'
        missingErrorMessage='Ingresa tu número de tarjeta.'
        :showEmptyInputError='tryToSubmit && !cardNumber'
      />
      <InputText
        label='Titular'
        placeholder='Titular'
        v-model='newCard.name'
        missingErrorMessage='Ingrese el titular.'
        :showEmptyInputError='tryToSubmit && !newCard.name'
      />
      <InputText
        label='Fecha de expiración'
        placeholder='Fecha de expiración'
        v-model='cardExpiry'
        missingErrorMessage='Ingresa la fecha de expiración.'
        :showEmptyInputError='tryToSubmit && !cardExpiry'
      />
      <InputText
        label='CVV'
        placeholder='CVV'
        v-model='cardCvc'
        missingErrorMessage='Ingresa tu cvv.'
        :showEmptyInputError='tryToSubmit && !cardCvc'
      />
      <div>
        <div class="c-notification c-notification c-notification--alert-notification">
          <h5>Importante:</h5>
          <div class="text">
            Para Tarjeta de Crédito/Débito puedes utilizar Visa, MasterCard y
            American Express, si utilizas tarjeta MasterCard Ripley contactase
            con tu banco previamente y valida si cuentas activada la opción de
            pagos online.
          </div>
        </div>
      </div>
      <div style='display: flex; gap: 12px'>
        <CustomCheckbox
          checkboxId='TYC'
          v-model='tyc'
        />
        <div>
          He leído y acepto:
          <a
            href="https://saio.holascharff.com/Files/terminos-y-condiciones-tarjeta.pdf"
            target="popup"
            onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-tarjeta.pdf','popup','width=600,height=600'); return false;"
            style="text-decoration: underline"
            class='text-m-bold'
            >Términos y Condiciones</a
          >
        </div>
      </div>
    </div>

    <div class='add_card_container--buttons'>
      <ButtonDefinitive
        label="Guardar"
        @click="validateCard()"
      />
      <ButtonDefinitive
        variant='tertiary'
        label="Cancelar"
        @click="setSection('list')"
      />
    </div>
  </div>
</template>

<script>
import { addCard } from "../../services/cards";
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import InputText from '@/components/Form/InputText.vue'
import CustomCheckbox from '@/components/Form/CustomCheckbox.vue'

export default {
  name: "AddEditCard",
  components: { ButtonDefinitive, InputText, CustomCheckbox, },
  data() {
    return {
      newCard: {
        alias: " ",
        name: '',
        number: null,
        year: null,
        month: null,
        cvv: null,
        email: null,
      },
      tyc: false,
      sessionToken: null,
      cardCvc: '',
      cardExpiry: '',
      cardNumber: '',
      tryToSubmit: false
    };
  },
  methods: {
    setDefaultNewCard() {
      this.newCard = {
        alias: null,
        name: null,
        number: null,
        year: null,
        month: null,
        cvv: null,
        email: null,
      };
    },
    setSection(value) {
      this.$store.dispatch("HELPER_SET_CURRENT_CARD_SECTION", value);
    },
    async timeout(ms) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async fetchCards() {
      this.showLoading();
      await this.$store
        .dispatch("GET_CARDS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const cards = success.data.result;
            this.$store.dispatch("GENERAL_SET_CARDS", cards);
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },

    async validateCard() {
      this.tryToSubmit = true
      try {
        if (!this.tyc) {
          this.showError("Debes aceptar los términos y condiciones.");
          return;
        }
        if (!this.newCard.name) {
          this.showError("Ingrese el nombre del titular");
          return;
        }
        if (!this.cardNumber) {
          this.showError("Ingrese el número de tarjeta");
          return;
        }
        if (!this.cardExpiry) {
          this.showError("Ingrese la fecha de expiración");
          return;
        }
        if (this.cardExpiry.length < 4) {
          this.showError("La fecha de expiración no es valida");
          return;
        }
        const monthCardExpirity = this.cardExpiry?.split("/")[0].trim();
        let yearCardExpirity = this.cardExpiry?.split("/")[1]?.trim();
        if (yearCardExpirity.length === 2) {
          yearCardExpirity = `20${yearCardExpirity}`;
        }
        if (yearCardExpirity.length !== 2 && yearCardExpirity.length !== 4) {
          this.showError("La fecha de expiración no es valida");
          return;
        }
        if (!this.cardCvc) {
          this.showError("Ingrese el CVV de su tarjeta");
          return;
        }
        const data = {
          cardNumber: this.cardNumber.replace(/\s+/g, ""),
          cardHolderName: this.newCard.name,
          cardExpirationDate: `${yearCardExpirity}/${monthCardExpirity}`,
          cardSecurityCode: this.cardCvc,
          alias: this.newCard.alias,
        };
        this.showLoading();
        const res = await addCard(data);
        console.log("🚀 ~ file: AddEditCard.vue:239 ~ validateCard ~ res", res);
        if (res.status === 200) {
          this.showOk("¡tarjeta agregada con éxito!", this.fetchCards);
          this.setSection("list");
          this.setDefaultNewCard();
        } else {
          this.showError(res.data.message);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: AddEditCard.vue:247 ~ validateCard ~ error",
          error
        );
        this.showError(
          error.message || "Ocurrio un error interno al agregar la tarjeta"
        );
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.newCard.email = this.user.email;
      // eslint-disable-next-line no-undef
      payform.setConfiguration(configuration);

      const elementStyles = {
        base: {
          color: "#FFF",
          fontWeight: 700,
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          placeholder: {
            color: "#999999",
          },
          autofill: {
            color: "#e39f48",
          },
        },

        invalid: {
          color: "#E25950",
          "::placeholder": {
            color: "#000",
          },
        },
      };
      /* Caso de uso: Controles independientes */
      // eslint-disable-next-line no-undef
      this.cardNumber = payform.createElement(
        "card-number",
        {
          style: elementStyles,
          placeholder: "Número de tarjeta",
        },
        "txtNumeroTarjeta"
      );
      // eslint-disable-next-line no-undef
      this.cardExpiry = payform.createElement(
        "card-expiry",
        {
          style: elementStyles,
          placeholder: "MM/YY",
        },
        "txtFechaVencimiento"
      );
      // eslint-disable-next-line no-undef
      this.cardCvc = payform.createElement(
        "card-cvc",
        {
          style: elementStyles,
          placeholder: "CVV",
        },
        "txtCvv"
      );
      this.$swal.close();
    });
  },
  computed: {
    currentCardSection: {
      get() {
        return this.$store.getters.HELPER_CURRENT_CARD_SECTION;
      },
      set(value) {
        this.$store.dispatch("HELPER_SET_CURRENT_CARD_SECTION", value);
      },
    },
    currentCardToEdit() {
      return this.$store.getters.HELPER_CURRENT_CARD_TO_EDIT;
    },
    user() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped lang='scss'>
.StripeElement {
  height: 40px;
  display: flex;
  align-items: center;
  height: 44px !important;
}

#txtCvv > div > iframe {
  width: 150px;
}
.add_card_container {
  height: 88%;
  overflow-y: auto;
  padding: 32px 20px;

  &--inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &--buttons {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
